import React from "react"
import { FaExternalLinkSquareAlt, FaFileDownload } from "react-icons/fa"

const Buttons = ({ links, className }) => {
  const renderLink = link => {
    switch (link.linkType) {
      case "internal":
        return (
          <a className="button" href={link.pageLink?.uri}>
            {link.linkText}
          </a>
        )
      case "external":
        return (
          <a
            className="button"
            href={link.externalUrl}
            target="_blank"
            rel="noreferrer nofollow"
          >
            {link.linkText} <FaExternalLinkSquareAlt className="icon" />
          </a>
        )

      case "file":
        return link.forceDownload ? (
          <a className="button" href={link.file.localFile.publicURL} download>
            <FaFileDownload className="icon" /> {link.linkText}
          </a>
        ) : (
          <a className="button" href={link.file.localFile.publicURL}>
            <FaFileDownload className="icon" /> {link.linkText}
          </a>
        )
      case "custom-int":
        return (
          <a className="button" href={`/#${link.anchorId}`}>
            {link.linkText}
          </a>
        )
      case "custom":
        return (
          <a
            className="button"
            href={`${link.customUrl}#${link.anchorId}`}
            target="_blank"
            rel="noreferrer nofollow"
          >
            {link.linkText} <FaExternalLinkSquareAlt className="icon" />
          </a>
        )
      default:
        return null
    }
  }

  return (
    <ul
      className={`link-list ${className ? className : ``}`}
      style={{ listStyle: `none`, margin: `2.5rem 0` }}
    >
      {links.map((link, index) => {
        return <li key={`button_${index}`}>{renderLink(link)}</li>
      })}
    </ul>
  )
}
export default Buttons
