import React, { useRef, useEffect, useState } from "react"
import parse from "html-react-parser"
import gsap from "gsap"
import { StaticQuery, graphql } from "gatsby"
import GravityFormForm from "gatsby-gravityforms-component"
import Inputmask from "inputmask"
import isMobilePhone from "validator/lib/isMobilePhone"
import { window } from "browser-monads"
import { BsArrowLeft } from "react-icons/bs"
import { CgFacebook } from "react-icons/cg"

import FadeLink from "@components/fade-link"
import FormSuccess from "@images/svg/success.svg"
import PreventionHouseLogo from "@images/svg/prevention-house-logo.svg"

import "./style.scss"

const Footer = ({
  isProgramPage,
  programSlug,
  programType,
  hasPreventionHouseCallout,
}) => {
  const contactFormContainer = useRef()
  const [confirmation, setConfirmation] = useState(null)

  const handleError = ({ values, error, reset }) => {
    console.error(values, error)
  }

  const handleSuccess = ({ values, reset, confirmations }) => {
    setConfirmation(confirmations[0])
  }

  useEffect(() => {
    if (confirmation) {
      const tl = gsap.timeline()

      const form = contactFormContainer.current
      const formConfirmation =
        form.getElementsByClassName("form-confimation")[0]

      const confirmationImage = formConfirmation.getElementsByTagName("svg")[0]
      const confirmationHeading =
        formConfirmation.getElementsByClassName("heading")[0]
      const confirmationBodyText =
        formConfirmation.getElementsByClassName("body-text")
      const confirmationButton =
        formConfirmation.getElementsByTagName("button")[0]

      tl.fromTo(
        confirmationImage,
        0.35,
        {
          opacity: 0,
          y: `15%`,
        },
        {
          opacity: 1,
          y: 0,
        }
      )

      tl.fromTo(
        [confirmationHeading, confirmationBodyText, confirmationButton],
        0.35,
        {
          opacity: 0,
          y: `15%`,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.1,
        }
      )
    }
  }, [confirmation])

  useEffect(() => {
    if (contactFormContainer.current) {
      let container = contactFormContainer.current
      let phoneFields = container.getElementsByClassName(
        "gravityform__field__input__phone"
      )
      let submitButton = container.getElementsByClassName(
        "gravityform__button"
      )[0]

      Array(...phoneFields).forEach(field => {
        Inputmask({ mask: "999-999-9999" }).mask(field)

        field.addEventListener("blur", e => {
          let valid = isMobilePhone(e.target.value)

          if (valid) {
            field.classList.remove("validation_error")
            submitButton.disabled = false
          } else {
            field.classList.add("validation_error")
            submitButton.disabled = true
          }
        })
      })
    }
  })

  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          wp {
            siteOptions {
              siteOptionsFooter {
                phFacebookUrl
                contactBodyText
                contactForm
                contactFormHeading
                contactImage {
                  altText
                  localFile {
                    publicURL
                  }
                }
                whoWeAreHeading
                whoWeAreText
                whoWeAreImage {
                  altText
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
          wpMenu(databaseId: { eq: 95 }) {
            id
            menuItems {
              menuItems: nodes {
                id
                title
                path
                target
                url
                order
                label
                cssClasses
                connectedNode {
                  node {
                    id
                  }
                }
              }
            }
          }
          allGfForm {
            edges {
              node {
                ...GravityFormComponent
              }
            }
          }
        }
      `}
      render={data => {
        const {
          contactBodyText,
          contactForm,
          contactFormHeading,
          contactImage,
          whoWeAreHeading,
          whoWeAreImage,
          whoWeAreText,
          phFacebookUrl,
        } = data.wp.siteOptions.siteOptionsFooter

        const { allGfForm } = data
        const { menuItems } = data.wpMenu.menuItems

        return (
          <footer>
            <div className="contact-form" ref={contactFormContainer}>
              <div className="container">
                <div className="columns">
                  <div className="column is-10 is-offset-1">
                    {!confirmation ? (
                      <div className="contact-form-wrapper" id={`contact-us`}>
                        <div className="columns">
                          <div className="column is-7">
                            {contactFormHeading || isProgramPage ? (
                              <h4 className="h1">
                                {isProgramPage
                                  ? `Inquire about this program`
                                  : contactFormHeading}
                              </h4>
                            ) : null}
                          </div>
                        </div>
                        <div className="columns">
                          <div className="column is-7">
                            {contactForm ? (
                              <GravityFormForm
                                id={parseInt(contactForm, 2)}
                                formData={allGfForm}
                                presetValues={{
                                  input_5: window.location.href,
                                  input_6: programSlug ? programSlug : "",
                                  input_7: programType ? programType : "",
                                }}
                                lambda={process.env.GATSBY_LAMBDA_ENDPOINT}
                                successCallback={handleSuccess}
                                errorCallback={handleError}
                              />
                            ) : null}
                          </div>
                          <div className="column is-4 is-offset-1">
                            <div className="contact-text">
                              {contactBodyText ? parse(contactBodyText) : null}
                            </div>
                          </div>
                        </div>
                        {contactImage && !confirmation ? (
                          <div className="contact-image">
                            <img
                              src={contactImage.localFile.publicURL}
                              alt={contactImage.altText}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="contact-form-wrapper" id={`contact-us`}>
                        <div className="form-confimation">
                          <div className="columns has-text-centered-mobile">
                            <div className="confirmation-image column is-5-desktop is-4-tablet has-text-centered is-flex-tablet">
                              <FormSuccess />
                            </div>
                            <div className="column is-7-desktop is-7-tablet is-offset-1-tablet is-flex-tablet">
                              {parse(confirmation.message)}

                              <button
                                className="button"
                                onClick={() => {
                                  setConfirmation(false)
                                }}
                              >
                                <BsArrowLeft /> {`Back to Contact Form`}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom container">
              {/* {hasPreventionHouseCallout && phFacebookUrl ? (
                <div className="columns">
                  <div className="column is-10 is-offset-1 ph-callout">
                <div className="ph-inner">
                <div className="columns">
                <div className="column is-6 is-flex-tablet">
                <PreventionHouseLogo />
                </div>
                <div className="column is-6 has-text-right is-flex-tablet">
                <a
                href={phFacebookUrl}
                rel="noreferrer nofollow"
                target="_blank"
                >
                {`Visit us on Facebook`}
                <CgFacebook
                style={{ width: `2rem`, height: `2rem` }}
                />
                </a>
                </div>
                </div>
                </div>
                  </div>
                </div>
              ) : null} */}

              <div className="columns who-we-are">
                <div className="column is-10 is-offset-1">
                  {whoWeAreHeading ? <h6>{whoWeAreHeading}</h6> : null}
                </div>
              </div>
              <div className="columns is-flex-tablet">
                <div className="column is-flex-tablet is-1-tablet is-12-mobile is-offset-1-tablet has-text-centered-mobile">
                  <img src={whoWeAreImage.localFile.publicURL} alt="" />
                </div>
                <div className="column is-flex-tablet is-9-tablet is-12-mobile body-text">
                  {contactBodyText ? parse(whoWeAreText) : null}
                </div>
              </div>
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <hr />
                </div>
              </div>
              <div className="columns is-flex-tablet menu-columns">
                <div className="column is-5 is-offset-1">
                  <ul className="menu">
                    {menuItems.map(menuItem => {
                      const { cssClasses, id, url, label, connectedNode } =
                        menuItem
                      return connectedNode ? (
                        <li key={id} className="menu-item">
                          <FadeLink className={cssClasses.join(" ")} to={url}>
                            {label}
                          </FadeLink>
                        </li>
                      ) : (
                        <li key={id} className="menu-item">
                          <a className={cssClasses.join(" ")} href={url}>
                            {label}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="column is-5 ">
                  {`${parse("&copy;")} ${new Date().getFullYear()}
                  Child & Youth Milopemahtesewin Services`}
                </div>
              </div>
            </div>
          </footer>
        )
      }}
    />
  )
}

export default Footer
