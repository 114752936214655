/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"

function SEO({ seo }) {
  const {
    title,
    metaDesc,
    opengraphUrl,
    opengraphType,
    opengraphTitle,
    opengraphSiteName,
    opengraphPublishedTime,
    opengraphModifiedTime,
    opengraphDescription,
    opengraphAuthor,
    opengraphImage,
    twitterDescription,
    twitterTitle,
    twitterImage,
  } = seo

  return (
    <Helmet
      htmlAttributes={{ lang: "en-CA" }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDesc,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: opengraphAuthor,
        },
        {
          name: `twitter:title`,
          content: twitterTitle ? twitterTitle : title,
        },
        {
          name: `twitter:description`,
          content: twitterDescription ? twitterDescription : metaDesc,
        },
        {
          property: `twitter:image`,
          content: twitterImage
            ? `${process.env.GATSBY_PRODUCTION_URL}${twitterImage.localFile.publicURL}`
            : ``,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: opengraphSiteName,
        },
        {
          property: `og:description`,
          content: opengraphDescription ? opengraphDescription : metaDesc,
        },
        {
          property: `og:image`,
          content: opengraphImage
            ? `${process.env.GATSBY_PRODUCTION_URL}${opengraphImage.localFile.publicURL}`
            : ``,
        },
        {
          property: `og:type`,
          content: opengraphType,
        },
        {
          property: `article:author`,
          content: opengraphAuthor,
        },
        {
          property: `article:published_time`,
          content: opengraphPublishedTime,
        },
        {
          property: `article:modified_time`,
          content: opengraphModifiedTime,
        },
      ]}
    />
  )
}

export default SEO
